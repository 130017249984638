.rect {
    text-align: center;
    font-size: 30px;
    padding-top: 10px;
    height: 100%;
}

.dx-box-item {
  
}



.small {
    height: 50px;
    border: 1px solid lightgray;
}
