
a {
    color: #8dc32b;
    text-decoration: none;
    outline: none;
    transition: all 0.3s ease-in;
}

.header-sticky--green {
    background: #8dc32b;
    height: 80px;
    top: 0;
    left: 0;
    right: 0;
}


.header-sticky__logo {
    margin: 15px 75px;
    width: 120px;
}

.rst-tagline {
    font-size: 21px;
    margin-top: 40px;
    display: inline-block;
    color: white;
    float: left;
    font-family: lato;
    font-weight: 700;
    color: #fff;
    text-transform: none;
}

.rst-header-menu {
    background: #405e3c;
    height: 49px;
    padding: 0 75px;
}

.header-sticky__menu {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 16px;
    font-family: "Lato Semibold", sans-serif;
}

    .header-sticky__menu li {
        display: inline-block;
        position: relative;
        padding: 0 38px;
    }

    .header-sticky__menu a {
        font-size: 16px;
        display: block;
        color: #fff;
        height: 49px;
        line-height: 49px;
    }
